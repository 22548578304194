<template>
  <div class="main-container">
    <History />
  </div>
</template>

<script>
import History from "../modules/history/History";
export default {
  name: "HistoryView",
  components: {
    History,
  },
};
</script>
